// import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
// import { useAttributePreference } from '../../common/util/preferences';

const sourceCustom = (urls) => ({
  type: 'raster',
  tiles: urls,
  tileSize: 256,
  maxzoom: 18,
});

// const sourceOpenWeather = (style, key) => sourceCustom([
//  `https://tile.openweathermap.org/map/${style}/{z}/{x}/{y}.png?appid=${key}`,
// ]);

export default () => {
  const t = useTranslation();

  // const openWeatherKey = useAttributePreference('openWeatherKey');
  // const tomTomKey = useAttributePreference('tomTomKey');
  // const hereKey = useAttributePreference('hereKey');
  // const customMapOverlay = useSelector((state) => state.session.server.overlayUrl);

  return [
    {
      id: 'openSeaMap',
      title: t('mapOpenSeaMap'),
      source: sourceCustom(['https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png']),
      available: true,
    },
  ];
};
